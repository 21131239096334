export const AppRoutes = {
	HOME: "https://mayicard.com",
	ABOUT: "/about",
	INDEX: "/",
	CONTACT: "/contact",
	LOGIN: "/login",
	SIGNUP: "/signup",

	CLERK_SIGN_IN: "/sign-in",
	CLERK_SIGN_UP: "/sign-up",
	CLERK_AFTER_SIGN_IN_URL: "/profile/select",
	CLERK_AFTER_SIGN_UP_URL: "/sign-in",

	DASHBOARD: "/dashboard",
	DASHBOARD_CLIENT: "/dashboard/client",
	DASHBOARD_CLIENT_TRANSACTIONS: "/dashboard/client/transactions",
	DASHBOARD_ORG: "/dashboard/organisation",
	PROFILE_SELECT: "/profile/select",
	ORGS: "/organisations",
	CLIENTS: "/clients?state=active",
	CLIENTS_ADD: "/clients/new",
	RESET_PASSWORD: "/reset-password",

	DEPOSITS: "/transactions/savings/deposit",
	WITHDRAWS: "/transactions/savings/withdraw",
	INTEREST: "/transactions/savings/interest",
	TRANSFERS: "/transactions/savings/interest",

	TRANSACTION_ADD: "/transactions/new",

	LOAN_REQUESTS: "/loans/contracts",
	LOAN_PRODUCTS: "/loans/products",
	LOAN_PRODUCTS_ADD: "/loans/products/new",
	DISBURSEMENTS: "/loans/tranches",
	REPAYMENTS: "/transactions/loans/REPAYMENT",

	Loans: {
		Products: {
			Index: "/loans/products",
			New: "/loans/products/new",
			Edit: "/loans/products/edit",
		},
		Contracts: {
			Index: "/loans/contracts",
			New: "/loans/contracts/new",
			Edit: "/loans/contracts/edit",
		},
		Tranches: {
			Index: "/loans/tranches",
			New: "/loans/tranches/new",
			Edit: "/loans/tranches/edit",
		},
	},
	Dashboard: {
		Index: "/dashboard",
		Client: "/dashboard/client/overview",
		Organisation: "/dashboard/organisation",
		Statement: "/dashboard/client/statement",
		Transactions: {
			Index: "/dashboard/client/transactions",
			New: "/dashboard/client/transactions/new",
		},
		Investments: {
			Index: "/dashboard/client/investments",
			New: "/dashboard/client/investments/new",
		},
		Loans: {
			Index: "/dashboard/client/loans",
			New: "/loans/contracts/new",
		},
	},
};
